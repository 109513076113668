import { Link } from "gatsby"
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap'
// import Logo from "../../images/logo.svg"
// import LogoBlack from "../../images/logo-black.svg"
import LogoBlack from "../../images/arabian-estates-logo-black.svg"
import NewLogo from "../../images/arabian-estates-logo-new.svg"
// import LogoBlackShort from "../../images/arabianholidayslogo.svg"
import ArabianHolidaysLogo from "../../images/arabian-holidays-logo.svg"
import  WhatsappIcon from "../../images/arabian-holidays-logo.svg";
import LogoWhite from "../../images/white-logo.svg"
import whatsappiconimg from "../../images/whatsapp-icon-new.png"
import Tabbing from "../Tabbing/Tabbing";
import './Header.scss';
import Helmet from 'react-helmet'

import Burger from './BurgerMenu'
import GenerateLink from "../../common/link/generate-link"
import { AppendScript } from "../../common/site/utils"

// import {BurgerMenu} from "../../common/queries/common_use_query"

const Header = (props) => {

  // const{loading, error, data} = BurgerMenu();


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      socialLink {
        primary_menu {
          menu_items {
            label
            menu_item {
              id
            }
          }
        }
      }
      globalModule {
        Address
        Email
        Phone
        Whatsapp
      }
    }
  }
`);

  const allmenus = data.glstrapi.socialLink.primary_menu;
  const global = data.glstrapi.globalModule;


  const [state, setState] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60)
    })
    if (window.innerWidth >= 1200) {
      setState(true)
    }
    
    AppendScript("https://apps.elfsight.com/p/platform.js", "elfsight");

    window.scrollTo(0, 0)
  }, [])


  /*const useCookieConsent = () => {
    React.useEffect(() => {
      // Function to load the cookie consent script
      const loadCookieConsent = () => {
        const script = document.createElement('script');
        script.src = "https://apps.elfsight.com/p/platform.js";
        script.charset = "UTF-8";
        script.id = "elfsight";
        document.head.appendChild(script);
      };
  
      // Function to handle user interactions
      const onUserInteraction = () => {
        loadCookieConsent();
        // Remove all event listeners after the script is loaded
        ["mousemove", "touchmove", "keypress", "scroll", "touchstart"].forEach(event =>
          document.removeEventListener(event, onUserInteraction)
        );
      };
  
      // Add event listeners for various user interactions
      ["mousemove", "touchmove", "keypress", "scroll", "touchstart"].forEach(event =>
        document.addEventListener(event, onUserInteraction)
      );
  
      // Clean up event listeners when component is unmounted
      return () => {
        ["mousemove", "touchmove", "keypress", "scroll", "touchstart"].forEach(event =>
          document.removeEventListener(event, onUserInteraction)
        );
      };
    }, []); // Empty dependency array ensures this runs once on mount
  };

  useCookieConsent();*/


  const tabbingData = {
    'Buy':
    {
      location: ["Location", "Rent", "Off Plan", "Holiday Lets", "list a property"]
    },
    'Rent':
    {
      location: ["Location 2", "Rent", "Off Plan", "Holiday Lets", "list a property"],
    },
    'Off Plan':
    {
      location: ["Location 3", "Rent", "Off Plan", "Holiday Lets", "list a property"],
    },
    'Holiday Lets':
    {
      location: ["Location 4", "Rent", "Off Plan", "Holiday Lets", "list a property"],
    }
  }

  return (
    <>
      <header className={scroll ? " header header--fixed " : "header"}>
        <Helmet>
          {/* <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=myxwqbkwc2tsezn8mxykjq" async="true"></script> */}
        </Helmet>
        <Container>
          <Row>
            <Col>
              <Navbar>
                <Navbar.Brand className="flex-shrink-0">
                  <Link aria-label="logo" to="/" className="logo">
                  {props?.isHoliday ?

<img className="white-logo new-logo" src={ArabianHolidaysLogo} alt="Logo" />
:
                    <img className="white-logo new-logo" src={NewLogo} alt="Logo" />
                                      }                    {props?.isHoliday ?

                    <img className="black-logo new-logo" src={ArabianHolidaysLogo} alt="Logo" />
                    :
                    <img className="black-logo new-logo" src={LogoBlack} alt="Logo" />
 
}
{props?.isHoliday ?

<img className="full-white-logo new-logo" src={ArabianHolidaysLogo} alt="Logo" />
:
                    <img className="full-white-logo new-logo" src={NewLogo} alt="Logo" />
}
                  </Link>
                </Navbar.Brand>
                {(state) && <Nav>
                  <div className="close-menu" onClick={() => setState(prev => !prev)}>
                    <i className="icon-cross"></i>
                  </div>
                  <div className="navigation">
                    <ul>
                      <li>
                        <Link aria-label="Find a Property" className="nav-link" to="/property/for-sale/">Find a Property</Link>
                        <div className="property-model">
                          <div className="property-wrap">
                            <div className="tabbing-panel">
                              <Tabbing isheader={true} tabbingData={tabbingData} />
                            </div>
                            <div className="related-services">
                              <div className="h4">Related Services</div>
                              <ul>
                                <li>
                                  <Link aria-label="Buying" className="related-searched" to="/property-services/buy-property/">Buying</Link>
                                </li>
                                <li>
                                  <Link aria-label="Selling" className="related-searched" to="/property-services/sell-property/">Selling</Link>
                                </li>
                                <li>
                                  <Link aria-label="Renting" className="related-searched" to="/property-services/rent-property/">Renting</Link>
                                </li>
                                <li>
                                  <Link aria-label="Landlords" className="related-searched" to="/property-services/landlords/">Landlords</Link>
                                </li>
                                {/* <li>
                                  <Link className="related-searched" to="/">Off Plan Projects</Link>
                                </li>
                                <li>
                                  <Link className="related-searched" to="/">Holiday Lets</Link>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      {allmenus && allmenus.map((item, index) => {
                        return (
                          <li>
                            <GenerateLink class="nav-link" link={item.menu_items.menu_item}>{item.menu_items.label}</GenerateLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </Nav>
                }
                <div className="header-btns">
                  <div className="social-wraps">
                    <a aria-label="telephone" href={`tel:${global.Phone}`} className="social-icon">
                      <i className="icon-telephone"></i>
                    </a>
                    <a aria-label="whatsapp" href={`https://wa.me/${global.Whatsapp}`} target="_blank" className="social-icon">
                      <i className="icon-whatsapp"></i>
                    </a>
                    
                    <a aria-label="whatsappiconimg" target="_blank" href={`https://wa.me/97143243137`}>
                      <div className="FixedWhatsAppIcon gap-12 contact-container-link btn-icon-whatsApp">
                        <img src={whatsappiconimg} alt="social-icon" width={40} height={40}/>
                      </div>
                    </a>

                    {/* <a aria-label="whatsappiconimg" target="_blank" href={`https://wa.me/+971527735457`}>
                      <div className="FixedWhatsAppIcon gap-12 contact-container-link btn-icon-whatsApp">
                        <a aria-label="whatsappiconimg" href="https://wa.me/97143243137" target="_blank" className="social-icon">
                          <img src={whatsappiconimg} alt="social-icon" width={40} height={40}/>
                        </a>
                      </div>
                    </a> */}
                  </div>
                  <div className="hamburger-button">
                    <Burger />
                  </div>
                </div>
              </Navbar>

            </Col>
          </Row>
        </Container>
      </header>
    </>
  )
}



export default Header
